import Logo from "../../assets/Images/MariachiArtisticoLogo.png"
import LogoType from "../../assets/Logo"

const Card = () => 
    <div className="min-h-screen bg-slate-800 flex flex-col items-center text-neutral-content">
        <img src={Logo} alt="Mariachi Artistico logo" className="max-w-xs m-2"/>
        <LogoType className=" m-2 max-w-sm"/>
        <h2 className="text-xl m-2">(914)912-1497</h2>
        <h2 className="text-xl m-2">francisco@mariachiartistico.com</h2>
        <div>
            <a href="https://www.thebash.com/mariachi-band/mariachi-artistico" className="btn btn-outline m-2">Reviews</a>
            <a href="https://instagram.com/mariachiartistico" className="btn btn-outline m-2">Instagram</a>
        </div>
    </div>


export default Card